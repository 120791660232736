<template>
  <div class="container">
    <div
      v-if="authenticityConfirmed"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <div class="alert alert-success" role="alert">
        <div>
          KEASLIAN KODE QR INI TERVERIFIKASI OLEH SISTEM KAMI. <br />
          {{ usedFor }}
        </div>
      </div>
      <div class="container emp-profile" v-if="anggota">
        <div class="row">
          <div class="col-md-4">
            <div class="profile-img">
              <img :src="'data:image/png;base64,' + foto" alt="Foto Diri" />
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12">
                <div class="profile-head">
                  <h3>{{ anggota.nama_lengkap }}</h3>
                  <h6>{{ anggota.nomor_kta }}</h6>
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        >Informasi</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-8">
                <div class="tab-content profile-tab" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Nomor KTA</h6>
                      </div>
                      <div class="col-md-6">
                        <p>{{ anggota.nomor_kta }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Nama Lengkap</h6>
                      </div>
                      <div class="col-md-6">
                        <p>{{ anggota.nama_lengkap }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Tanggal Lahir</h6>
                      </div>
                      <div class="col-md-6">
                        <p>{{ localDate(anggota.tanggal_lahir) || "-" }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Nomor Telepon</h6>
                      </div>
                      <div class="col-md-6">
                        <p>{{ anggota.no_hp }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Provinsi</h6>
                      </div>
                      <div class="col-md-6">
                        <p>
                          {{
                            (anggota.Propinsi && anggota.Propinsi.nama) || "-"
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Kota</h6>
                      </div>
                      <div class="col-md-6">
                        <p>
                          {{ (anggota.Kotum && anggota.Kotum.nama) || "-" }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Kecamatan</h6>
                      </div>
                      <div class="col-md-6">
                        <p>
                          {{
                            (anggota.Kecamatan && anggota.Kecamatan.nama) || "-"
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container emp-profile" v-if="merchandise">
        <div class="row">
          <div class="col-md-3">
            <div class="profile-img">
              <img
                :src="'data:image/png;base64,' + fotoProduk1"
                alt="Foto Produk"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="profile-img">
              <img
                :src="'data:image/png;base64,' + fotoProduk2"
                alt="Foto Produk 2"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <div class="profile-head">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        >Informasi</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-8">
                <div class="tab-content profile-tab" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Nama Produk</h6>
                      </div>
                      <div class="col-md-6">
                        <p>{{ merchandise.nama_produk }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Nama Vendor</h6>
                      </div>
                      <div class="col-md-6">
                        <p>{{ merchandise.nama_vendor }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Ukuran</h6>
                      </div>
                      <div class="col-md-6">
                        <p>{{ merchandise.ukuran }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Jenis Bahan</h6>
                      </div>
                      <div class="col-md-6">
                        <p>{{ merchandise.jenis_bahan }}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Harga</h6>
                      </div>
                      <div class="col-md-6">
                        <p>
                          {{ merchandise.harga }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Nama Pemilik</h6>
                      </div>
                      <div class="col-md-6">
                        <p>
                          {{
                            (merchandise.anggota &&
                              merchandise.anggota.nama_lengkap) ||
                            "-"
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <h6>Nomor KTA</h6>
                      </div>
                      <div class="col-md-6">
                        <p>
                          {{
                            (merchandise.anggota &&
                              merchandise.anggota.nomor_kta) ||
                            "-"
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex flex-column justify-content-center align-items-center"
      v-else
    >
      <div class="alert alert-danger" role="alert">
        <div>KEASLIAN KODE QR INI TIDAK TERVERIFIKASI OLEH SISTEM KAMI.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { anggotaService, qrCodeService } from "@/services";
export default {
  data() {
    return {
      anggota: "",
      merchandise: "",
      vendor: "",
      authenticityConfirmed: false,
      foto: "",
      fotoProduk1: "",
      fotoProduk2: "",
    };
  },
  methods: {
    localDate(date) {
      if (date === null) return "-";
      const date2 = date != null && new Date(Date.parse(date));
      return new Intl.DateTimeFormat("id-ID", {
        dateStyle: "full",
        timeStyle: "long",
      }).format(date2);
    },
  },
  computed: {
    usedFor() {
      if (this.anggota)
        return "KODE QR INI DI PRODUKSI UNTUK IDENTITAS KTA BERIKUT INI:";
      if (this.merchandise)
        return "KODE QR INI DI PRODUKSI UNTUK MERCHANDISE BERIKUT INI:";
      return "PRODUK INI RESMI DI PRODUKSI OLEH VENDOR TERDAFTAR DI Taekwondo Indonesia.";
    },
  },
  async mounted() {
    try {
      const response = await qrCodeService.getByCode(
        this.$route.params.qr_code
      );
      this.anggota = response.data.anggota;
      this.merchandise = response.data.Merchandise;
      this.authenticityConfirmed = true;
    } catch (error) {
      // Cek di Tabel Anggota
      try {
        const response = await anggotaService.getByQrCode(
          this.$route.params.qr_code
        );
        this.anggota = response.data;
        this.authenticityConfirmed = true;
      } catch (error) {
        this.authenticityConfirmed = false;
      }
    }

    if (this.anggota) {
      try {
        const response_foto_diri = await anggotaService.getFotoDiri(
          this.anggota.id
        );
        const buffer = Buffer.from(response_foto_diri.data).toString("base64");
        this.foto = buffer;
      } catch (error) {
        console.error("Gagal memuat foto.");
      }
    }

    if (this.merchandise) {
      if (this.merchandise.foto_produk_1_path) {
        const response = await this.axios.get(
          `/merchandise/${this.merchandise.foto_produk_1_path}`,
          {
            responseType: "arraybuffer",
          }
        );

        const buffer = Buffer.from(response.data).toString("base64");
        this.fotoProduk1 = buffer;
      }
      if (this.merchandise.foto_produk_2_path) {
        const response = await this.axios.get(
          `/merchandise/${this.merchandise.foto_produk_2_path}`,
          {
            responseType: "arraybuffer",
          }
        );

        const buffer = Buffer.from(response.data).toString("base64");
        this.fotoProduk2 = buffer;
      }
    }
  },
};
</script>

<style scoped>
body {
  background: -webkit-linear-gradient(left, #3931af, #00c6ff);
}
.emp-profile {
  padding: 3%;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 0.5rem;
  background: #fff;
}
.profile-img {
  text-align: center;
}
.profile-img img {
  width: 50%;
  height: 100%;
}
.profile-head h5 {
  color: #333;
}
.profile-head h6 {
  color: #0062cc;
}
.profile-edit-btn {
  border: none;
  border-radius: 1.5rem;
  width: 70%;
  padding: 2%;
  font-weight: 600;
  color: #6c757d;
  cursor: pointer;
}
.proile-rating {
  font-size: 12px;
  color: #818182;
  margin-top: 5%;
}
.proile-rating span {
  color: #495057;
  font-size: 15px;
  font-weight: 600;
}
.profile-head .nav-tabs {
  margin-bottom: 5%;
}
.profile-head .nav-tabs .nav-link {
  font-weight: 600;
  border: none;
}
.profile-head .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #0062cc;
}
.profile-work {
  padding: 14%;
  margin-top: -15%;
}
.profile-work p {
  font-size: 12px;
  color: #818182;
  font-weight: 600;
  margin-top: 10%;
}
.profile-work a {
  text-decoration: none;
  color: #495057;
  font-weight: 600;
  font-size: 14px;
}
.profile-work ul {
  list-style: none;
}
.profile-tab label {
  font-weight: 600;
}
.profile-tab p {
  font-weight: 600;
  color: #0062cc;
}
</style>
